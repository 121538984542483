/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  useContext,
  useReducer,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { Modal } from "@mui/material";
import { Box, Typography } from "@mui/material";
import PostPopup from "../Components/PostPopup";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Tooltip from "@mui/material/Tooltip";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { format } from "date-fns";
import { Store } from "../Store";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

const createClusterCustomIcon = (cluster) => {
  const count = cluster.getChildCount();
  const backgroundColor = "#F95C3D"; // Customize background color

  return L.divIcon({
    html: `<div style="background-color: ${backgroundColor}; border-radius: 50%; width: 30px; height: 30px; line-height: 30px; text-align: center; color: white;">${count}</div>`,
    className: "custom-cluster-icon",
    iconSize: [30, 30],
  });
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const reducer2 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingAllContactList: true };
    case "FETCHED":
      return {
        ...state,
        dataAllContactList: action.payload,
        loadingAllContactList: false,
      };
    case "FETCH_FAILED":
      return {
        ...state,
        loadingAllContactList: false,
        errorAllContactList: action.payload,
      };
    default:
      return state;
  }
};

const typeNames = {
  1: "Product",
  2: "Service",
  3: "Event",
  4: "Job",
  5: "Insight",
  6: "Education",
};

const getTypeName = (postType) => typeNames[postType] || "Unknown Type";

function MapsView(props) {
  const { posts } = props;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [mapCenter] = useState([-25.0, 133.0]);
  const [mapZoom] = useState(4);
  const bounds = [
    [-90, -180],
    [90, 180],
  ];

  const [setPosition] = useState(null);
  const location = useLocation();

  // tooltip menu
  const [anchorElPost, setAnchorElPost] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const edits = ["Update Post", "Delete Post"];
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [socialMediaOptions, setSocialMediaOptions] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1); // Initialize the ref with the initial page value
  const [rerender, setRerender] = useState(false); // Dummy state to trigger re-render
  const flagOpenCluster = useRef(false); // Initialize the ref with the initial  value

  const [currentClusterEvent, setCurrentClusterEvent] = useState(null);

  const navigate = useNavigate();

  const readMoreStyle = {
    color: "#c2bebe",
    cursor: "pointer",
  };

  const [dataUser, dispatch] = useReducer(reducer, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const [{ dataAllContactList }, dispatch2] = useReducer(reducer2, {
    dataAllContactList: [],
    loadingAllContactList: true,
    errorAllContactList: "",
  });

  const markerIcon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [41, 41],
  });

  const markerIconJob = new L.Icon({
    iconUrl: "../../images/job_icon_map.png",
    iconRetinaUrl: "../../images/job_icon_map.png",
    iconSize: [35, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [20, 20],
  });

  const markerIconEvent = new L.Icon({
    iconUrl: "../../images/event_icon_map.png",
    iconRetinaUrl: "../../images/event_icon_map.png",
    iconSize: [35, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [20, 20],
  });

  const markerIconProduct = new L.Icon({
    iconUrl: "../../images/product_icon_map.png",
    iconRetinaUrl: "../../images/product_icon_map.png",
    iconSize: [35, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [20, 20],
  });

  const markerIconService = new L.Icon({
    iconUrl: "../../images/service_icon_map.png",
    iconRetinaUrl: "../../images/service_icon_map.png",
    iconSize: [35, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [20, 20],
  });

  const markerIconInsight = new L.Icon({
    iconUrl: "../../images/insight_icon.png",
    iconRetinaUrl: "../../images/insight_icon.png",
    iconSize: [35, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [20, 20],
  });

  // const markerIconUser = new L.Icon({
  //   iconUrl: "../../images/service_icon_map.png",
  //   // iconRetinaUrl: "../../images/service_icon_map.png",
  //   iconSize: [35, 35],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  //   tooltipAnchor: [16, -28],
  //   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  //   shadowSize: [20, 20],
  // });
  function createMarkerIcon(iconUrl) {
    return new L.Icon({
      iconUrl: iconUrl,
      // iconRetinaUrl: iconUrl,
      iconSize: [35, 35],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      shadowSize: [20, 20],
    });
  }

  L.Marker.prototype.options.icon = markerIcon;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0.1px solid gainsboro",
    borderRadius: "1rem",
    boxShadow: "1px 2px 1px gainsboro",
    p: 4,
  };

  const markerRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null) {
        dispatch({
          type: "FETCHING",
        });
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
            {
              headers: { Authorization: `Bearer ${userInfo.data.token}` },
            }
          );
          dispatch({
            type: "FETCHED",
            payload: data.data,
          });
        } catch (error) {
          dispatch({
            type: "FETCH_FAILED",
            payload: error.message,
          });
          toast.error(getError(error));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    fetchData();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    dispatch2({
      type: "FETCHING",
    });

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/getAllContactList`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );

      const socialMediaOptions = data.data.map((item) => ({
        value: item.id.toString(),
        label: item.name,
        image: item.image,
        url: item.url,
      }));

      dispatch2({
        type: "FETCHED",
        payload: data.data,
      });

      setSocialMediaOptions(socialMediaOptions);
    } catch (error) {
      dispatch2({
        type: "FETCH_FAILED",
        payload: error.message,
      });
    }

    // const map = document.querySelector('.leaflet-container');
    // if (map) {
    //   map.addEventListener('click', handleMapClick);
    // }

    return () => {
      // if (map) {
      //   map.removeEventListener('click', handleMapClick);
      // }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleMapClick = (event) => {
  //   console.log(flagOpenCluster.current)
  //   if (!event.dragging && !flagOpenCluster.current) {
  //     currentPageRef.current = 1; // Reset currentPageRef.current when map is clicked (not dragged)
  //     console.log('currentPageRef.current reset to 1');
  //   }
  // };

  useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [setPosition]
  );

  function SetProfilePicture(post) {
    const isNull = post.post.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture post={post.post} />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="mb-3 mt-3"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }

  function ProfilePicture(post) {
    return (
      <img className="mb-3 mt-3" src={post.post.picture} alt="profilepicture" />
    );
  }

  function SetUsername(post) {
    if (post.post.username === null) {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {post.post.name}
        </p>
      );
    } else {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {post.post.username}
        </p>
      );
    }
  }

  function SetDefaultImage(post) {
    const isNull = post.post.images;
    const images = post.post.images || [];

    const prevImage = (event) => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      event.stopPropagation();
    };

    const nextImage = (event) => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      event.stopPropagation();
    };

    const showNavigationButtons = images.length > 1;

    if (isNull.length === 0 || isNull === null) {
      return <></>;
    } else {
      return (
        <div className="relative w-[5rem] h-[10rem] bg-white rounded-md">
          <img
            src={images[currentIndex]}
            alt={`Image ${currentIndex + 1}`}
            className="w-full h-full object-contain rounded-lg shadow-none "
          />

          {showNavigationButtons && (
            <div>
              <button
                type="button"
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full text-xl p-1"
                onClick={prevImage}
              >
                &#9664;
              </button>

              <button
                type="button"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full text-xl p-1"
                onClick={nextImage}
              >
                &#9654;
              </button>
            </div>
          )}
        </div>
      );
    }
  }

  const date_diff_indays = function (date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(
        dt2.getFullYear(),
        dt2.getMonth(),
        dt2.getDate(),
        dt2.getHours(),
        dt2.getMinutes(),
        dt2.getSeconds()
      ) -
        Date.UTC(
          dt1.getFullYear(),
          dt1.getMonth(),
          dt1.getDate(),
          dt1.getHours(),
          dt1.getMinutes(),
          dt1.getSeconds()
        )) /
        1000
    );
  };

  const openContact = (urlSocialMedia, usernameSocialMedia, post) => {
    if (dataAllContactList !== null) {
      if (urlSocialMedia) {
        let contactId = Object.keys(post.contact).find(
          (key) => post.contact[key].id === usernameSocialMedia.id
        );
        if (contactId !== undefined) {
          let url = dataAllContactList.find(
            (item) => item.id === contactId
          )?.url;

          // Check if the usernameSocialMedia.value starts with "http://" or "https://"
          if (
            usernameSocialMedia.value &&
            (usernameSocialMedia.value.startsWith("http://") ||
              usernameSocialMedia.value.startsWith("https://"))
          ) {
            url = usernameSocialMedia.value;
          } else {
            // If not, append it to the URL from dataAllContactList
            if (url === null) {
              url = "tel:" + getPhoneNumber(usernameSocialMedia.value);
            } else if (
              !url.startsWith("http://") &&
              !url.startsWith("https://")
            ) {
              url = "https://" + url;
            }
            url += usernameSocialMedia.value;
          }

          window.open(url);
        } else {
          alert("Contact not found!");
        }
      }
    }
  };

  const getPhoneNumber = (username) => {
    return "";
  };

  const UploadDate = ({ timestamp }) => {
    let currentDate = new Date();
    let uploadDate = new Date(timestamp);

    let diffDate = date_diff_indays(uploadDate, currentDate);
    let date = "";
    if (diffDate >= 604800) {
      date = Math.floor(diffDate / 604800) + "w";
      return date;
    } else if (diffDate >= 86400) {
      date = Math.floor(diffDate / 86400) + "d";
      return date;
    } else if (diffDate >= 3600) {
      date = Math.floor(diffDate / 3600) + "h";
      return date;
    } else if (diffDate >= 60) {
      date = Math.floor(diffDate / 60) + "m";
      return date;
    } else {
      if (diffDate < 0) {
        diffDate = 0;
      }
      date = diffDate + "s";
      return date;
    }
  };

  const autoHyperlink = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-[#F95C3D]"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [showMore, setShowMore] = useState(true);
    const toggleReadMore = () => {
      setShowMore(!showMore);
    };

    if (text.length <= 100) {
      return (
        <p className="whitespace-pre-wrap break-words">{autoHyperlink(text)}</p>
      );
    } else {
      const sliceLimit = 100;
      const endOfSentence = text.lastIndexOf(" ", sliceLimit);
      const displayText = showMore
        ? text.slice(0, endOfSentence) + "..."
        : text;

      return (
        <p className="whitespace-pre-wrap break-words">
          {autoHyperlink(displayText)}
          <span
            onClick={toggleReadMore}
            className="whitespace-nowrap text-sm text-[#F95C3D] cursor-pointer"
          >
            {showMore ? " read more" : "show less"}
          </span>
        </p>
      );
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElPost(event);
  };

  const handleCloseUserMenu = () => {
    setAnchorElPost(null);
  };

  function handleCloseUpdate() {
    setShowModalUpdate(false);
  }

  function handleCloseDelete() {
    setShowModalDelete(false);
  }

  // const handleButtonCloseUpdate = () => {
  //   setShowModalUpdate(false);
  // };
  // const handleButtonCloseDelete = () => {
  //   setShowModalDelete(false);
  // };

  // function handleButtonUpdate() {
  //   navigate('/update_post', { state: { post } });
  // }

  // const handleButtonDelete = async () => {
  //   try {
  //     await axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/deletePost/${post.id}`, {
  //       headers: { Authorization: `Bearer ${userInfo.data.token}` },
  //     });
  //     toast.success('Post deleted successfully!');
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 3000);
  //   } catch (error) {
  //     toast.error(getError(error));
  //   }
  // };

  const handleSeeDetailsClick = (post) => {
    navigate("/detail_post", { state: { post } });
  };

  const anchorEl = useRef(null);

  useEffect(() => {
    if (anchorElPost) {
      // Render the Menu component
    }
  }, [anchorElPost]);

  // Effect to handle cluster click when currentPage changes
  useEffect(() => {
    if (currentClusterEvent) {
      handleClusterClick(currentClusterEvent);
    }
  }, [rerender]);

  const handlePreviousClick = () => {
    currentPageRef.current = Math.max(currentPageRef.current - 1, 1);
    setRerender((prev) => !prev);
  };

  const handleNextClick = (totalLength) => {
    currentPageRef.current = Math.min(currentPageRef.current + 1, totalLength);
    setRerender((prev) => !prev);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClusterClick = (event) => {
    const cluster = event.layer;
    const map = event.target;
    let maxZoom = map._maxZoom;
    let currZoom = map._zoom;

    if (currZoom !== maxZoom) {
      return;
    }

    flagOpenCluster.current = true;
    const childMarkers = cluster.getAllChildMarkers();
    let totalLength = childMarkers.length;
    if (totalLength === 2) {
      cluster.spiderfy();
      return;
    } else {
      setCurrentClusterEvent(event);
    }

    // Check if the clicked layer is a cluster
    // if (cluster instanceof L.MarkerCluster) {

    // Get the current zoom level

    const itemsPerPage = 1; // Number of items per page

    const totalPages = Math.ceil(childMarkers.length / itemsPerPage);

    const startIndex = (currentPageRef.current - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (currentPageRef.current > totalLength) {
      currentPageRef.current = 1;
    }

    const currentMarkers = childMarkers.slice(startIndex, endIndex);

    // Check if it's the maximum zoom level or greater

    const childMarkersContent =
      currentMarkers &&
      currentMarkers.map((childMarker, index) => {
        const { _latlng, options } = childMarker;
        const onePost = options.options.post;
        return (
          <>
            <div>
              <li
                key={index}
                style={{ display: "flex", width: "100%" }}
                className="border-b-2 border-gray-500 mb-4 overflow-auto max-h-64 w-full"
              >
                {/* Render your post content here */}
                <SetDefaultImage post={onePost} />
                <div className="flex-grow min-w-1/2">
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="profilePost flex">
                      <div
                        className="mr-8 ml-[0.5rem]"
                        style={{ width: "2.5em", marginRight: "2%" }}
                      >
                        <SetProfilePicture post={options.options.post} />
                      </div>
                      <div
                        className="flex items-center"
                        style={{
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <SetUsername post={options.options.post} />
                      </div>
                      <div className="flex items-center ml-2">
                        {options.options.post.verified ? (
                          <img
                            className="w-4 h-4"
                            alt="verified"
                            src="../../images/icon_done.png"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="flex items-center">
                        {getTypeName(onePost.post_type) === "Product" ? (
                          <Tooltip title="Product">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#16A34A"
                              />
                              <path
                                d="M11.0207 2.29547C11.9694 1.90151 13.0306 1.90151 13.9793 2.29547L21.8529 5.56498C22.5463 5.85293 23 6.54282 23 7.30923V17.6908C23 18.4572 22.5463 19.1471 21.8529 19.4351L13.9793 22.7045C13.0306 23.0985 11.9694 23.0985 11.0207 22.7045L3.14715 19.4351C2.45372 19.1471 2 18.4572 2 17.6908V7.30923C2 6.54282 2.45372 5.85293 3.14715 5.56498L11.0207 2.29547ZM13.3876 3.79054C12.8184 3.55417 12.1816 3.55417 11.6124 3.79054L9.60302 4.62495L17.5062 7.77307L20.3088 6.66463L13.3876 3.79054ZM15.3257 8.63544L7.47546 5.50842L4.7198 6.65272L12.5014 9.75241L15.3257 8.63544ZM3.575 17.6908C3.575 17.8003 3.63982 17.8988 3.73887 17.94L11.6124 21.2095C11.6456 21.2233 11.6792 21.2364 11.7128 21.2485V11.1693L3.575 7.92767V17.6908ZM13.3876 21.2095L21.2611 17.94C21.3602 17.8988 21.425 17.8003 21.425 17.6908V7.95255L13.2878 11.1707V21.2483C13.3213 21.2361 13.3546 21.2232 13.3876 21.2095Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Service" ? (
                          <Tooltip title="Service">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#2563EB"
                              />
                              <path
                                d="M16.9625 2C16.6642 2 16.3915 2.16852 16.2582 2.43532L15.2082 4.53532C15.0904 4.77082 15.0981 5.0496 15.2287 5.2782L16.175 6.93413V12.5H15.3875C14.9526 12.5 14.6 12.8526 14.6 13.2875V19.325C14.6 21.3546 16.2454 23 18.275 23C20.3047 23 21.95 21.3546 21.95 19.325V13.2875C21.95 12.8526 21.5974 12.5 21.1625 12.5H20.375V6.93413L21.3213 5.2782C21.4519 5.0496 21.4597 4.77082 21.3418 4.53532L20.2918 2.43532C20.1585 2.16852 19.8858 2 19.5875 2H16.9625ZM18.8 12.5H17.75V6.725C17.75 6.58795 17.7142 6.45328 17.6463 6.33429L16.8053 4.86272L17.4492 3.575H19.1008L19.7447 4.86272L18.9037 6.33429C18.8358 6.45328 18.8 6.58795 18.8 6.725V12.5ZM16.175 19.325V16.7H20.375V19.325C20.375 20.4848 19.4348 21.425 18.275 21.425C17.1152 21.425 16.175 20.4848 16.175 19.325ZM20.375 15.125H16.175V14.075H20.375V15.125ZM9.90264 2.14229C9.66012 2.04614 9.38572 2.07632 9.1699 2.22292C8.9541 2.36951 8.8249 2.61346 8.8249 2.87435V6.72487C8.8249 7.30477 8.35479 7.77487 7.7749 7.77487C7.19499 7.77487 6.7249 7.30477 6.7249 6.72487V2.87444C6.7249 2.61355 6.59568 2.36958 6.37987 2.223C6.16404 2.07641 5.88963 2.04622 5.64711 2.14239C3.51205 2.98902 2 5.07322 2 7.5125C2 9.74276 3.26406 11.6762 5.1125 12.6383V20.3373C5.1125 21.8077 6.30454 22.9998 7.775 22.9998C9.24545 22.9998 10.4375 21.8077 10.4375 20.3373V12.6383C12.2859 11.6762 13.55 9.74276 13.55 7.5125C13.55 5.07313 12.0378 2.98885 9.90264 2.14229ZM10.3999 6.72487V4.23338C11.3606 5.00354 11.975 6.1868 11.975 7.5125C11.975 9.27237 10.8923 10.7813 9.35377 11.4059C9.05678 11.5264 8.8625 11.815 8.8625 12.1355V20.3373C8.8625 20.9379 8.37561 21.4248 7.775 21.4248C7.17439 21.4248 6.6875 20.9379 6.6875 20.3373V12.1355C6.6875 11.815 6.49322 11.5264 6.19623 11.4059C4.65764 10.7813 3.575 9.27237 3.575 7.5125C3.575 6.18691 4.18927 5.00371 5.1499 4.23356V6.72487C5.1499 8.17462 6.32514 9.34987 7.7749 9.34987C9.22464 9.34987 10.3999 8.17462 10.3999 6.72487Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Event" ? (
                          <Tooltip title="Event">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#EA580C"
                              />
                              <path
                                d="M19.2083 2C21.3024 2 23 3.69758 23 5.79167V19.2083C23 21.3024 21.3024 23 19.2083 23H5.79167C3.69758 23 2 21.3024 2 19.2083V5.79167C2 3.69758 3.69758 2 5.79167 2H19.2083ZM21.25 8.41667H3.75V19.2083C3.75 20.3359 4.66408 21.25 5.79167 21.25H19.2083C20.3359 21.25 21.25 20.3359 21.25 19.2083V8.41667ZM7.54167 15.4167C8.34709 15.4167 9 16.0695 9 16.875C9 17.6805 8.34709 18.3333 7.54167 18.3333C6.73625 18.3333 6.08333 17.6805 6.08333 16.875C6.08333 16.0695 6.73625 15.4167 7.54167 15.4167ZM12.5 15.4167C13.3055 15.4167 13.9583 16.0695 13.9583 16.875C13.9583 17.6805 13.3055 18.3333 12.5 18.3333C11.6945 18.3333 11.0417 17.6805 11.0417 16.875C11.0417 16.0695 11.6945 15.4167 12.5 15.4167ZM7.54167 10.75C8.34709 10.75 9 11.4029 9 12.2083C9 13.0138 8.34709 13.6667 7.54167 13.6667C6.73625 13.6667 6.08333 13.0138 6.08333 12.2083C6.08333 11.4029 6.73625 10.75 7.54167 10.75ZM12.5 10.75C13.3055 10.75 13.9583 11.4029 13.9583 12.2083C13.9583 13.0138 13.3055 13.6667 12.5 13.6667C11.6945 13.6667 11.0417 13.0138 11.0417 12.2083C11.0417 11.4029 11.6945 10.75 12.5 10.75ZM17.4583 10.75C18.2638 10.75 18.9167 11.4029 18.9167 12.2083C18.9167 13.0138 18.2638 13.6667 17.4583 13.6667C16.6529 13.6667 16 13.0138 16 12.2083C16 11.4029 16.6529 10.75 17.4583 10.75ZM19.2083 3.75H5.79167C4.66408 3.75 3.75 4.66408 3.75 5.79167V6.66667H21.25V5.79167C21.25 4.66408 20.3359 3.75 19.2083 3.75Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Job" ? (
                          <Tooltip title="Job">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#9333EA"
                              />
                              <path
                                d="M10.4583 3.75H14.5417C15.0249 3.75 15.4167 4.14175 15.4167 4.625V6.66667H9.58333V4.625C9.58333 4.14175 9.97509 3.75 10.4583 3.75ZM7.83333 4.625V6.66667H5.79167C3.69758 6.66667 2 8.36425 2 10.4583V19.2083C2 21.3024 3.69758 23 5.79167 23H19.2083C21.3024 23 23 21.3024 23 19.2083V10.4583C23 8.36425 21.3024 6.66667 19.2083 6.66667H17.1667V4.625C17.1667 3.17525 15.9914 2 14.5417 2H10.4583C9.00859 2 7.83333 3.17525 7.83333 4.625ZM21.25 10.4583V12.2083C21.25 13.3359 20.3359 14.25 19.2083 14.25H14.8333V13.6667C14.8333 13.0223 14.311 12.5 13.6667 12.5H11.3333C10.689 12.5 10.1667 13.0223 10.1667 13.6667V14.25H5.79167C4.66408 14.25 3.75 13.3359 3.75 12.2083V10.4583C3.75 9.33075 4.66408 8.41667 5.79167 8.41667H19.2083C20.3359 8.41667 21.25 9.33075 21.25 10.4583ZM21.25 15.404V19.2083C21.25 20.3359 20.3359 21.25 19.2083 21.25H5.79167C4.66408 21.25 3.75 20.3359 3.75 19.2083V15.404C4.33934 15.7813 5.03996 16 5.79167 16H10.1667C10.1667 16.6443 10.689 17.1667 11.3333 17.1667H13.6667C14.311 17.1667 14.8333 16.6443 14.8333 16H19.2083C19.96 16 20.6607 15.7813 21.25 15.404Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Insight" ? (
                          <Tooltip title="Insight">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#3B82F6"
                              />
                              <path
                                d="M12 2C9.243 2 7 4.243 7 7C7 9.285 8.279 11.19 10 12.164V15H14V12.164C15.721 11.19 17 9.285 17 7C17 4.243 14.757 2 12 2zM11 16H13V17H11V16zM11 18H13V19H11V18z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Unknown">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#4B5563"
                              />
                              <path
                                d="M12.5 2C8.90653 2 6 4.76186 6 8.17647C6 8.68814 6.43653 9.10294 6.975 9.10294C7.51347 9.10294 7.95 8.68814 7.95 8.17647C7.95 5.7852 9.98346 3.85294 12.5 3.85294C15.0165 3.85294 17.05 5.7852 17.05 8.17647C17.05 9.22867 16.762 9.92997 16.3624 10.4735C15.9414 11.0463 15.3656 11.4904 14.6702 11.9956C14.6112 12.0385 14.5514 12.0819 14.4907 12.1256C13.1772 13.0745 11.525 14.268 11.525 16.8235V17.1324C11.525 17.644 11.9615 18.0588 12.5 18.0588C13.0385 18.0588 13.475 17.644 13.475 17.1324V16.8235C13.475 15.1963 14.4032 14.5221 15.7959 13.5103L15.8548 13.4676C16.5407 12.9692 17.3461 12.3709 17.961 11.5347C18.5973 10.6693 19 9.59484 19 8.17647C19 4.76186 16.0935 2 12.5 2ZM12.5 23C13.218 23 13.8 22.447 13.8 21.7647C13.8 21.0825 13.218 20.5294 12.5 20.5294C11.782 20.5294 11.2 21.0825 11.2 21.7647C11.2 22.447 11.782 23 12.5 23Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        )}
                      </div>

                      {dataUser.user_id === onePost.user_id &&
                      userInfo !== null ? (
                        <div className="flex items-center">
                          <Tooltip title="Edit Post">
                            <MoreVertSharpIcon
                              onClick={(e) => {
                                anchorEl.current = e.currentTarget;
                                handleOpenUserMenu(e.currentTarget);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                          <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorEl.current}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorElPost)}
                            onClose={handleCloseUserMenu}
                          >
                            {edits.map((edit) => (
                              <MenuItem key={edit}>
                                {edit === "Update Post"
                                  ? `<button onClick={handleOpenUpdate}>
                                <Typography textAlign="center" className="no-underline text-black">${edit}</Typography>
                              </button>`
                                  : `<button onClick={handleOpenDelete}>
                                <Typography textAlign="center" className="no-underline text-black">${edit}</Typography>
                              </button>`}
                                <Modal
                                  open={showModalUpdate}
                                  onClose={handleCloseUpdate}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="modal-notification-style">
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                      style={{
                                        marginBottom: "10%",
                                        marginTop: "5%",
                                        textAlign: "center",
                                      }}
                                    >
                                      Are you sure want to update this post?
                                    </Typography>
                                    <div className="flex justify-center">
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonUpdate}
                                      >
                                        Update
                                      </button>
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonCloseUpdate}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </Box>
                                </Modal>
                                <Modal
                                  open={showModalDelete}
                                  onClose={handleCloseDelete}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="modal-notification-style">
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                      style={{
                                        marginBottom: "10%",
                                        marginTop: "5%",
                                        textAlign: "center",
                                      }}
                                    >
                                      Are you sure want to delete this post?
                                    </Typography>
                                    <div className="flex justify-center">
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonDelete}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonCloseDelete}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </Box>
                                </Modal>
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 ml-[0.5rem]">
                    <UploadDate timestamp={onePost.created_at} />, located in{" "}
                    {onePost.location}{" "}
                  </div>
                  <h1 className="text-lg mt-4 ml-[0.5rem]">
                    {onePost.posttitle}
                  </h1>
                  <div className="ml-[0.5rem]">
                    <ReadMore>{onePost.description}</ReadMore>
                  </div>
                  {onePost.price ? (
                    <div className="text-xl ml-[0.5rem] font-bold mt-4">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: onePost.abbreviation,
                        maximumFractionDigits: 0,
                      }).format(onePost.price)}
                    </div>
                  ) : null}

                  <div
                    className=" flex justify-end"
                    style={{ marginRight: "10px" }}
                  >
                    {onePost.contact &&
                      Object.keys(onePost.contact).map((contactId) => {
                        const contact = dataAllContactList.find(
                          (item) => item.id.toString() === contactId
                        );

                        return contact ? (
                          <button
                            key={contact.id}
                            onClick={() =>
                              openContact(
                                contact,
                                onePost.contact[contactId],
                                onePost
                              )
                            }
                          >
                            <img
                              src={
                                Array.isArray(socialMediaOptions) &&
                                socialMediaOptions.find(
                                  (option) => option.label === contact.name
                                )?.image
                              }
                              alt={contact.name}
                              className="lineButton ml-1"
                            />
                          </button>
                        ) : null;
                      })}
                  </div>
                  <div className="mt-4 flex justify-end mb-4">
                    {/* ... Other components ... */}
                    <button
                      onClick={() => handleSeeDetailsClick(onePost)}
                      style={{ color: "#F95C3D" }}
                    >
                      See details &gt;
                    </button>
                    {/* ... Other components ... */}
                  </div>
                </div>
              </li>
            </div>
          </>
        );
      });

    // Create a container div for childMarkersContent

    const containerDiv = document.createElement("div");
    // containerDiv.style.width = "300px";

    ReactDOM.render(
      <ul className="h-full w-[300px] md:w-[450px] ">
        {childMarkersContent}
        <div className="flex justify-around">
          {currentPageRef.current === totalLength ? (
            <>
              <button onClick={handlePreviousClick}>Previous</button>
            </>
          ) : currentPageRef.current === 1 ? (
            <>
              <button onClick={() => handleNextClick(totalLength)}>Next</button>
            </>
          ) : (
            <>
              {" "}
              <button onClick={handlePreviousClick}>Previous</button>
              <button onClick={() => handleNextClick(totalLength)}>Next</button>
            </>
          )}
        </div>
      </ul>,
      containerDiv
    );

    // Bind the custom popup
    // cluster.bindPopup(containerDiv).openPopup();
    // cluster.bindPopup(popupContent).openPopup();

    const popup = cluster.bindPopup(containerDiv, {
      autoPan: true,
      closeButton: true,
      autoPanPaddingTopLeft: [0],
      autoPanPaddingBottomRight: [0, 100],
    });

    popup.openPopup();

    // setPopupContent(containerDiv);
    // }
  };

  const handleClusterUserClick = (event) => {
    const cluster = event.layer;
    const map = event.target;
    let maxZoom = map._maxZoom;
    let currZoom = map._zoom;

    if (currZoom !== maxZoom) {
      return;
    }

    flagOpenCluster.current = true;
    const childMarkers = cluster.getAllChildMarkers();
    let totalLength = childMarkers.length;
    if (totalLength === 2) {
      cluster.spiderfy();
      return;
    } else {
      setCurrentClusterEvent(event);
    }

    // Check if the clicked layer is a cluster
    // if (cluster instanceof L.MarkerCluster) {

    // Get the current zoom level

    const itemsPerPage = 1; // Number of items per page

    const totalPages = Math.ceil(childMarkers.length / itemsPerPage);

    const startIndex = (currentPageRef.current - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (currentPageRef.current > totalLength) {
      currentPageRef.current = 1;
    }

    const currentMarkers = childMarkers.slice(startIndex, endIndex);

    // Check if it's the maximum zoom level or greater

    const childMarkersContent =
      currentMarkers &&
      currentMarkers.map((childMarker, index) => {
        const { _latlng, options } = childMarker;
        const onePost = options.options.post;
        return (
          <>
            <div>
              <li
                key={index}
                style={{ display: "flex", width: "100%" }}
                className="border-b-2 border-gray-500 mb-4 overflow-auto max-h-64 w-full"
              >
                {/* Render your post content here */}
                <SetDefaultImage post={onePost} />
                <div className="flex-grow min-w-1/2">
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="profilePost flex">
                      <div
                        className="mr-8 ml-[0.5rem]"
                        style={{ width: "2.5em", marginRight: "2%" }}
                      >
                        <SetProfilePicture post={options.options.post} />
                      </div>
                      <div
                        className="flex items-center"
                        style={{
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <SetUsername post={options.options.post} />
                      </div>
                      <div className="flex items-center ml-2">
                        {options.options.post.verified ? (
                          <img
                            className="w-4 h-4"
                            alt="verified"
                            src="../../images/icon_done.png"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="flex items-center">
                        {getTypeName(onePost.post_type) === "Product" ? (
                          <Tooltip title="Product">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#16A34A"
                              />
                              <path
                                d="M11.0207 2.29547C11.9694 1.90151 13.0306 1.90151 13.9793 2.29547L21.8529 5.56498C22.5463 5.85293 23 6.54282 23 7.30923V17.6908C23 18.4572 22.5463 19.1471 21.8529 19.4351L13.9793 22.7045C13.0306 23.0985 11.9694 23.0985 11.0207 22.7045L3.14715 19.4351C2.45372 19.1471 2 18.4572 2 17.6908V7.30923C2 6.54282 2.45372 5.85293 3.14715 5.56498L11.0207 2.29547ZM13.3876 3.79054C12.8184 3.55417 12.1816 3.55417 11.6124 3.79054L9.60302 4.62495L17.5062 7.77307L20.3088 6.66463L13.3876 3.79054ZM15.3257 8.63544L7.47546 5.50842L4.7198 6.65272L12.5014 9.75241L15.3257 8.63544ZM3.575 17.6908C3.575 17.8003 3.63982 17.8988 3.73887 17.94L11.6124 21.2095C11.6456 21.2233 11.6792 21.2364 11.7128 21.2485V11.1693L3.575 7.92767V17.6908ZM13.3876 21.2095L21.2611 17.94C21.3602 17.8988 21.425 17.8003 21.425 17.6908V7.95255L13.2878 11.1707V21.2483C13.3213 21.2361 13.3546 21.2232 13.3876 21.2095Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Service" ? (
                          <Tooltip title="Service">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#2563EB"
                              />
                              <path
                                d="M16.9625 2C16.6642 2 16.3915 2.16852 16.2582 2.43532L15.2082 4.53532C15.0904 4.77082 15.0981 5.0496 15.2287 5.2782L16.175 6.93413V12.5H15.3875C14.9526 12.5 14.6 12.8526 14.6 13.2875V19.325C14.6 21.3546 16.2454 23 18.275 23C20.3047 23 21.95 21.3546 21.95 19.325V13.2875C21.95 12.8526 21.5974 12.5 21.1625 12.5H20.375V6.93413L21.3213 5.2782C21.4519 5.0496 21.4597 4.77082 21.3418 4.53532L20.2918 2.43532C20.1585 2.16852 19.8858 2 19.5875 2H16.9625ZM18.8 12.5H17.75V6.725C17.75 6.58795 17.7142 6.45328 17.6463 6.33429L16.8053 4.86272L17.4492 3.575H19.1008L19.7447 4.86272L18.9037 6.33429C18.8358 6.45328 18.8 6.58795 18.8 6.725V12.5ZM16.175 19.325V16.7H20.375V19.325C20.375 20.4848 19.4348 21.425 18.275 21.425C17.1152 21.425 16.175 20.4848 16.175 19.325ZM20.375 15.125H16.175V14.075H20.375V15.125ZM9.90264 2.14229C9.66012 2.04614 9.38572 2.07632 9.1699 2.22292C8.9541 2.36951 8.8249 2.61346 8.8249 2.87435V6.72487C8.8249 7.30477 8.35479 7.77487 7.7749 7.77487C7.19499 7.77487 6.7249 7.30477 6.7249 6.72487V2.87444C6.7249 2.61355 6.59568 2.36958 6.37987 2.223C6.16404 2.07641 5.88963 2.04622 5.64711 2.14239C3.51205 2.98902 2 5.07322 2 7.5125C2 9.74276 3.26406 11.6762 5.1125 12.6383V20.3373C5.1125 21.8077 6.30454 22.9998 7.775 22.9998C9.24545 22.9998 10.4375 21.8077 10.4375 20.3373V12.6383C12.2859 11.6762 13.55 9.74276 13.55 7.5125C13.55 5.07313 12.0378 2.98885 9.90264 2.14229ZM10.3999 6.72487V4.23338C11.3606 5.00354 11.975 6.1868 11.975 7.5125C11.975 9.27237 10.8923 10.7813 9.35377 11.4059C9.05678 11.5264 8.8625 11.815 8.8625 12.1355V20.3373C8.8625 20.9379 8.37561 21.4248 7.775 21.4248C7.17439 21.4248 6.6875 20.9379 6.6875 20.3373V12.1355C6.6875 11.815 6.49322 11.5264 6.19623 11.4059C4.65764 10.7813 3.575 9.27237 3.575 7.5125C3.575 6.18691 4.18927 5.00371 5.1499 4.23356V6.72487C5.1499 8.17462 6.32514 9.34987 7.7749 9.34987C9.22464 9.34987 10.3999 8.17462 10.3999 6.72487Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Event" ? (
                          <Tooltip title="Event">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#EA580C"
                              />
                              <path
                                d="M19.2083 2C21.3024 2 23 3.69758 23 5.79167V19.2083C23 21.3024 21.3024 23 19.2083 23H5.79167C3.69758 23 2 21.3024 2 19.2083V5.79167C2 3.69758 3.69758 2 5.79167 2H19.2083ZM21.25 8.41667H3.75V19.2083C3.75 20.3359 4.66408 21.25 5.79167 21.25H19.2083C20.3359 21.25 21.25 20.3359 21.25 19.2083V8.41667ZM7.54167 15.4167C8.34709 15.4167 9 16.0695 9 16.875C9 17.6805 8.34709 18.3333 7.54167 18.3333C6.73625 18.3333 6.08333 17.6805 6.08333 16.875C6.08333 16.0695 6.73625 15.4167 7.54167 15.4167ZM12.5 15.4167C13.3055 15.4167 13.9583 16.0695 13.9583 16.875C13.9583 17.6805 13.3055 18.3333 12.5 18.3333C11.6945 18.3333 11.0417 17.6805 11.0417 16.875C11.0417 16.0695 11.6945 15.4167 12.5 15.4167ZM7.54167 10.75C8.34709 10.75 9 11.4029 9 12.2083C9 13.0138 8.34709 13.6667 7.54167 13.6667C6.73625 13.6667 6.08333 13.0138 6.08333 12.2083C6.08333 11.4029 6.73625 10.75 7.54167 10.75ZM12.5 10.75C13.3055 10.75 13.9583 11.4029 13.9583 12.2083C13.9583 13.0138 13.3055 13.6667 12.5 13.6667C11.6945 13.6667 11.0417 13.0138 11.0417 12.2083C11.0417 11.4029 11.6945 10.75 12.5 10.75ZM17.4583 10.75C18.2638 10.75 18.9167 11.4029 18.9167 12.2083C18.9167 13.0138 18.2638 13.6667 17.4583 13.6667C16.6529 13.6667 16 13.0138 16 12.2083C16 11.4029 16.6529 10.75 17.4583 10.75ZM19.2083 3.75H5.79167C4.66408 3.75 3.75 4.66408 3.75 5.79167V6.66667H21.25V5.79167C21.25 4.66408 20.3359 3.75 19.2083 3.75Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Job" ? (
                          <Tooltip title="Job">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#9333EA"
                              />
                              <path
                                d="M10.4583 3.75H14.5417C15.0249 3.75 15.4167 4.14175 15.4167 4.625V6.66667H9.58333V4.625C9.58333 4.14175 9.97509 3.75 10.4583 3.75ZM7.83333 4.625V6.66667H5.79167C3.69758 6.66667 2 8.36425 2 10.4583V19.2083C2 21.3024 3.69758 23 5.79167 23H19.2083C21.3024 23 23 21.3024 23 19.2083V10.4583C23 8.36425 21.3024 6.66667 19.2083 6.66667H17.1667V4.625C17.1667 3.17525 15.9914 2 14.5417 2H10.4583C9.00859 2 7.83333 3.17525 7.83333 4.625ZM21.25 10.4583V12.2083C21.25 13.3359 20.3359 14.25 19.2083 14.25H14.8333V13.6667C14.8333 13.0223 14.311 12.5 13.6667 12.5H11.3333C10.689 12.5 10.1667 13.0223 10.1667 13.6667V14.25H5.79167C4.66408 14.25 3.75 13.3359 3.75 12.2083V10.4583C3.75 9.33075 4.66408 8.41667 5.79167 8.41667H19.2083C20.3359 8.41667 21.25 9.33075 21.25 10.4583ZM21.25 15.404V19.2083C21.25 20.3359 20.3359 21.25 19.2083 21.25H5.79167C4.66408 21.25 3.75 20.3359 3.75 19.2083V15.404C4.33934 15.7813 5.03996 16 5.79167 16H10.1667C10.1667 16.6443 10.689 17.1667 11.3333 17.1667H13.6667C14.311 17.1667 14.8333 16.6443 14.8333 16H19.2083C19.96 16 20.6607 15.7813 21.25 15.404Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : getTypeName(onePost.post_type) === "Insight" ? (
                          <Tooltip title="Insight">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#3B82F6"
                              />
                              <path
                                d="M12 2C9.243 2 7 4.243 7 7C7 9.285 8.279 11.19 10 12.164V15H14V12.164C15.721 11.19 17 9.285 17 7C17 4.243 14.757 2 12 2zM11 16H13V17H11V16zM11 18H13V19H11V18z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Unknown">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="5"
                                fill="#4B5563"
                              />
                              <path
                                d="M12.5 2C8.90653 2 6 4.76186 6 8.17647C6 8.68814 6.43653 9.10294 6.975 9.10294C7.51347 9.10294 7.95 8.68814 7.95 8.17647C7.95 5.7852 9.98346 3.85294 12.5 3.85294C15.0165 3.85294 17.05 5.7852 17.05 8.17647C17.05 9.22867 16.762 9.92997 16.3624 10.4735C15.9414 11.0463 15.3656 11.4904 14.6702 11.9956C14.6112 12.0385 14.5514 12.0819 14.4907 12.1256C13.1772 13.0745 11.525 14.268 11.525 16.8235V17.1324C11.525 17.644 11.9615 18.0588 12.5 18.0588C13.0385 18.0588 13.475 17.644 13.475 17.1324V16.8235C13.475 15.1963 14.4032 14.5221 15.7959 13.5103L15.8548 13.4676C16.5407 12.9692 17.3461 12.3709 17.961 11.5347C18.5973 10.6693 19 9.59484 19 8.17647C19 4.76186 16.0935 2 12.5 2ZM12.5 23C13.218 23 13.8 22.447 13.8 21.7647C13.8 21.0825 13.218 20.5294 12.5 20.5294C11.782 20.5294 11.2 21.0825 11.2 21.7647C11.2 22.447 11.782 23 12.5 23Z"
                                fill="white"
                              />
                            </svg>
                          </Tooltip>
                        )}
                      </div>

                      {dataUser.user_id === onePost.user_id &&
                      userInfo !== null ? (
                        <div className="flex items-center">
                          <Tooltip title="Edit Post">
                            <MoreVertSharpIcon
                              onClick={(e) => {
                                anchorEl.current = e.currentTarget;
                                handleOpenUserMenu(e.currentTarget);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                          <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorEl.current}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorElPost)}
                            onClose={handleCloseUserMenu}
                          >
                            {edits.map((edit) => (
                              <MenuItem key={edit}>
                                {edit === "Update Post"
                                  ? `<button onClick={handleOpenUpdate}>
                                <Typography textAlign="center" className="no-underline text-black">${edit}</Typography>
                              </button>`
                                  : `<button onClick={handleOpenDelete}>
                                <Typography textAlign="center" className="no-underline text-black">${edit}</Typography>
                              </button>`}
                                <Modal
                                  open={showModalUpdate}
                                  onClose={handleCloseUpdate}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="modal-notification-style">
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                      style={{
                                        marginBottom: "10%",
                                        marginTop: "5%",
                                        textAlign: "center",
                                      }}
                                    >
                                      Are you sure want to update this post?
                                    </Typography>
                                    <div className="flex justify-center">
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonUpdate}
                                      >
                                        Update
                                      </button>
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonCloseUpdate}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </Box>
                                </Modal>
                                <Modal
                                  open={showModalDelete}
                                  onClose={handleCloseDelete}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="modal-notification-style">
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                      style={{
                                        marginBottom: "10%",
                                        marginTop: "5%",
                                        textAlign: "center",
                                      }}
                                    >
                                      Are you sure want to delete this post?
                                    </Typography>
                                    <div className="flex justify-center">
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonDelete}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
                                        // onClick={handleButtonCloseDelete}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </Box>
                                </Modal>
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 ml-[0.5rem]">
                    <UploadDate timestamp={onePost.created_at} />, located in{" "}
                    {onePost.location}{" "}
                  </div>
                  <h1 className="text-lg mt-4 ml-[0.5rem]">
                    {onePost.posttitle}
                  </h1>
                  <div className="ml-[0.5rem]">
                    <ReadMore>{onePost.description}</ReadMore>
                  </div>
                  {onePost.price ? (
                    <div className="text-xl ml-[0.5rem] font-bold mt-4">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: onePost.abbreviation,
                        maximumFractionDigits: 0,
                      }).format(onePost.price)}
                    </div>
                  ) : null}

                  <div
                    className=" flex justify-end"
                    style={{ marginRight: "10px" }}
                  >
                    {onePost.contact &&
                      Object.keys(onePost.contact).map((contactId) => {
                        const contact = dataAllContactList.find(
                          (item) => item.id.toString() === contactId
                        );

                        return contact ? (
                          <button
                            key={contact.id}
                            onClick={() =>
                              openContact(
                                contact,
                                onePost.contact[contactId],
                                onePost
                              )
                            }
                          >
                            <img
                              src={
                                Array.isArray(socialMediaOptions) &&
                                socialMediaOptions.find(
                                  (option) => option.label === contact.name
                                )?.image
                              }
                              alt={contact.name}
                              className="lineButton ml-1"
                            />
                          </button>
                        ) : null;
                      })}
                  </div>
                  <div className="mt-4 flex justify-end mb-4">
                    {/* ... Other components ... */}
                    <button
                      onClick={() => handleSeeDetailsClick(onePost)}
                      style={{ color: "#F95C3D" }}
                    >
                      See details &gt;
                    </button>
                    {/* ... Other components ... */}
                  </div>
                </div>
              </li>
            </div>
          </>
        );
      });

    // Create a container div for childMarkersContent

    const containerDiv = document.createElement("div");
    // containerDiv.style.width = "300px";

    ReactDOM.render(
      <ul className="h-full w-[300px] md:w-[450px] ">
        {childMarkersContent}
        <div className="flex justify-around">
          {currentPageRef.current === totalLength ? (
            <>
              <button onClick={handlePreviousClick}>Previous</button>
            </>
          ) : currentPageRef.current === 1 ? (
            <>
              <button onClick={() => handleNextClick(totalLength)}>Next</button>
            </>
          ) : (
            <>
              {" "}
              <button onClick={handlePreviousClick}>Previous</button>
              <button onClick={() => handleNextClick(totalLength)}>Next</button>
            </>
          )}
        </div>
      </ul>,
      containerDiv
    );

    // Bind the custom popup
    // cluster.bindPopup(containerDiv).openPopup();
    // cluster.bindPopup(popupContent).openPopup();

    const popup = cluster.bindPopup(containerDiv, {
      autoPan: true,
      closeButton: true,
      autoPanPaddingTopLeft: [0],
      autoPanPaddingBottomRight: [0, 100],
    });

    popup.openPopup();

    // setPopupContent(containerDiv);
    // }
  };

  const handleMarkerClick = (username) => {
    navigate(`/${username}`);
  };

  const categoryIcons = {
    1: markerIconProduct,
    2: markerIconService,
    3: markerIconEvent,
    4: markerIconJob,
    5: markerIconInsight,
  };

  function handleCloseSignInModal() {
    setShowSignInModal(false);
  }

  function handleSignInButtonModal() {
    setShowSignInModal(false);
    navigate("/signin");
  }

  function handleCancelButtonModal() {
    setShowSignInModal(false);
  }

  return (
    <div
      className={`${
        location.pathname === "/"
          ? "static w-full h-full"
          : "static w-full mt-4"
      }`}
    >
      <MapContainer
        //  style={{ background: 'transparent'}}
        id="map"
        center={mapCenter}
        zoom={mapZoom}
        scrollWheelZoom={true}
        minZoom={2}
        maxBounds={bounds}
        maxBoundsViscosity={1.0}
        zoomControl={false}
        className={`${
          location.pathname === "/"
            ? "h-full lg:h-full"
            : "h-full"
        } rounded-3xl`}
        // style={{ width: "10vp", marginTop: "10px"}}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          noWrap={true}
        />
        <ZoomControl position="topright" />
        {!userInfo ? (
          <>
            {posts[0] && posts[0].category ? (
              <>
                <MarkerClusterGroup
                  iconCreateFunction={createClusterCustomIcon}
                  showCoverageOnHover={false}
                  spiderfyOnMaxZoom={false}
                  onClick={() => setShowSignInModal(true)}
                >
                  {posts.map((post, index) => {
                    const icon = categoryIcons[post.category.value];

                    if (icon && post.lat && post.long) {
                      return (
                        <Marker
                          key={index}
                          position={[post.lat, post.long]}
                          icon={icon}
                          options={{ post }}
                          eventHandlers={{
                            click: () => setShowSignInModal(true),
                          }}
                        ></Marker>
                      );
                    }

                    return null;
                  })}
                </MarkerClusterGroup>
              </>
            ) : (
              <>
                <MarkerClusterGroup
                  iconCreateFunction={createClusterCustomIcon}
                  showCoverageOnHover={false}
                  spiderfyOnMaxZoom={true}
                  onClick={() => setShowSignInModal(true)}
                >
                  {posts.map((post, index) => {
                    if (post.lat && post.long) {
                      return (
                        <Link to={`/${post.username}`}>
                          <Marker
                            key={index}
                            position={[post.lat, post.long]}
                            icon={createMarkerIcon(post.picture)}
                            options={{ post }}
                            eventHandlers={{
                              click: () => setShowSignInModal(true),
                            }}
                          ></Marker>
                        </Link>
                      );
                    }
                  })}
                </MarkerClusterGroup>
              </>
            )}
          </>
        ) : (
          <>
            {posts[0] && posts[0].category ? (
              <>
                <MarkerClusterGroup
                  iconCreateFunction={createClusterCustomIcon}
                  showCoverageOnHover={false}
                  spiderfyOnMaxZoom={false}
                  onClick={handleClusterClick}
                >
                  {posts.map((post, index) => {
                    const icon = categoryIcons[post.category.value];

                    if (icon && post.lat && post.long) {
                      return (
                        <Marker
                          key={index}
                          position={[post.lat, post.long]}
                          icon={icon}
                          options={{ post }}
                        >
                          <Popup>
                            <PostPopup key={post.id} post={post} />
                          </Popup>
                        </Marker>
                      );
                    }

                    return null;
                  })}
                </MarkerClusterGroup>
              </>
            ) : (
              <>
                <MarkerClusterGroup
                  iconCreateFunction={createClusterCustomIcon}
                  showCoverageOnHover={false}
                  spiderfyOnMaxZoom={true}
                  onClick={handleClusterUserClick}
                >
                  {posts.map((post, index) => {
                    if (post.lat || post.long) {
                      return (
                        <Link to={`/${post.username}`}>
                          <Marker
                            key={index}
                            position={[post.lat, post.long]}
                            icon={createMarkerIcon(post.picture)}
                            options={{ post }}
                            eventHandlers={{
                              click: () => handleMarkerClick(post.username),
                            }}
                          ></Marker>
                        </Link>
                      );
                    }
                  })}
                </MarkerClusterGroup>
              </>
            )}
          </>
        )}
      </MapContainer>
      <Modal
        open={showSignInModal}
        onClose={handleCloseSignInModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-notification-style">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10%",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            Sign in to use this feature
          </Typography>
          <div className="flex justify-center">
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleSignInButtonModal}
            >
              Sign in
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
              onClick={handleCancelButtonModal}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default MapsView;
